class MessageReminder {
  constructor (messageName, remindAfterMin) {
    this.messageName = messageName
    this.remindAfterMin = remindAfterMin
  }

  #getStorageKey (userId) {
    return `je.pcweb.${this.messageName}RemindRequestedAt_${userId}`
  }

  requestReminder (user) {
    localStorage.setItem(
      this.#getStorageKey(user.nameIdentifier),
      String(new Date().getTime())
    )
  }

  shouldRemind (user) {
    if (!user) return false

    const remindRequestedAt = localStorage.getItem(this.#getStorageKey(user.nameIdentifier))
    if (!remindRequestedAt) return true

    const remindAfter = new Date(Number(remindRequestedAt) + this.remindAfterMin * 60000)
    return remindAfter < new Date().getTime()
  }
}

export default MessageReminder
