import { logout } from '@je-pc/auth'
import { PcErrorPage } from '@je-pc/ui-components'
import { TENANT } from '../constants'

const Settings = () =>
  import(
    /* webpackChunkName: "settings" */ '@/views/Settings'
  )
const Menu = () =>
  import(
    /* webpackChunkName: "menu" */ '@/views/Menu'
  )
const OrdersAndInvoices = () =>
  import(
    /* webpackChunkName: "ordersAndInvoices" */ '@/views/OrdersAndInvoices'
  )
const BusinessHelp = () =>
  import(
    /* webpackChunkName: "businessHelp" */ '@/views/BusinessHelp'
  )
const MarketingHub = () =>
  import(
    /* webpackChunkName: "marketingHub" */ '@/views/MarketingHub'
  )

const routes = [
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: () => {
      logout()
    },
    meta: {
      allowAnonymous: true
    }
  },
  {
    name: 'logout-restaurant',
    path: '/logout-restaurant',
    beforeEnter: () => {
      logout({ isChangingRestaurant: true })
    },
    meta: {
      allowAnonymous: true
    }
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      name: 'your restaurant',
      group: 'your restaurant'
    }
  },
  {
    name: 'menu',
    path: '/menu',
    component: Menu,
    meta: {
      name: 'menu',
      group: 'menu'
    }
  },
  {
    name: 'orders-and-invoices',
    path: '/orders-and-invoices',
    component: OrdersAndInvoices,
    meta: {
      name: 'orders and invoices',
      group: 'orders and invoices'
    }
  },
  {
    name: 'help',
    path: '/help',
    component: BusinessHelp,
    meta: {
      name: 'business help',
      group: 'business help'
    }
  },
  {
    name: 'marketing',
    path: '/marketing',
    component: MarketingHub,
    meta: {
      name: 'marketing hub',
      group: 'marketing'
    }
  },
  {
    name: 'error',
    path: '/error',
    component: PcErrorPage,
    props: {
      tenant: TENANT
    },
    meta: {
      allowAnonymous: true
    }
  },
  {
    name: '404',
    path: '*',
    component: PcErrorPage,
    props: {
      errorStatus: 404,
      tenant: TENANT
    },
    meta: {
      allowAnonymous: true
    }
  }
]

export default routes
