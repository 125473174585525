export const TENANT = 'uk'
export const SMARTGATEWAY_URL = 'https://smartgateway-qa28.cogpart.je-labs.com'

export const EMAIL_LOGIN_REMIND_AFTER_MIN = 1440 // 24 hours
export const EMAIL_LOGIN_REMIND_MESSAGE_NAME = 'emailLogin'

export const MARKETING_MESSAGE_REMIND_AFTER_MIN = 1440 // 24 hours
export const MARKETING_MESSAGE_NAME = 'marketingMessage'

export const FEATURE_NAMES = {
  emailLoginSetup: 'emailLoginSetup',
  forceEmailLoginMigration: 'forceEmailLoginMigration',
  marketingMessage: 'marketingMessage'
}

export const ACCOUNT_SETTINGS_PATH = '/settings/account'

export const HIDE_HEADING_ROUTES = ['/analytics']

export const IS_MARKETING_POPUP_BANNER_ENABLED = false
