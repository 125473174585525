<template>
  <component
    :is="linkElem"
    v-bind="$attrs"
    :to="linkTo"
    :href="linkTo"
    rel="noopener"
    class="flex items-center font-bold hover:underline"
    :class="linkClasses"
    :data-cy="linkCyData"
    v-on="$listeners"
  >
    <!-- link icon-->
    <span
      class="w-8 h-8 flex justify-center items-center"
    >
      <pc-icon
        v-if="linkIcon"
        :name="linkIcon"
        class="w-6 h-6"
      />
    </span>
    <!-- link text -->
    <slot>
      <span
        class="hidden  tablet:inline-block"
      >
        {{ linkText }}</span>
    </slot>
  </component>
</template>

<script>
import { isMobile, PcIcon } from '@je-pc/ui-components'
export default {
  components: {
    PcIcon
  },
  mixins: [isMobile],
  props: {
    to: {
      default: '',
      type: String
    },
    icon: {
      type: String,
      default: ''
    },
    cyData: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: '',
      validator: variant => (variant === '' || ['close', 'back'].includes(variant))
    }
  },
  computed: {
    variants () {
      return {
        close: {
          icon: 'close',
          cyData: 'closeButton'
        },
        back: {
          icon: this.isMobile ? 'back' : 'arrowLongLeft',
          text: this.$t('back'),
          cyData: 'backButton',
          desktopClasses: 'content-default'
        }
      }
    },
    preparedVariant () {
      if (!this.variant) return
      return this.variants[this.variant]
    },
    preparedRoute () {
      const { path } = this.$route
      return path.endsWith('/') ? path.slice(0, -1) : path
    },
    getBackUrl () {
      const { referer } = this.$route.query
      if (referer) {
        return referer
      }
      const routesList = this.preparedRoute.split('/')
      routesList.pop()
      return routesList.join('/')
    },
    linkTo () {
      return this.to || this.getBackUrl
    },
    linkElem () {
      return this.linkTo.match(/^(http(s)?):\/\//) ? 'a' : 'router-link'
    },
    linkText () {
      return this.preparedVariant?.text
    },
    linkIcon () {
      return this.icon || this.preparedVariant?.icon
    },
    linkCyData () {
      return this.cyData || this.preparedVariant?.cyData
    },
    linkClasses () {
      return this.isMobile ? '' : this.preparedVariant?.desktopClasses
    }
  }
}
</script>
