<template>
  <pc-sidebar position="right">
    <template #activator="{ openSidebar }">
      <div
        tabindex="0"
        class="flex items-center cursor-pointer"
        data-test="user-info-trigger"
        @click="openSidebar"
      >
        <restaurant-image />
      </div>
    </template>

    <template #default="{ closeSidebar }">
      <div class="divide-y divide-color-default">
        <div class="hidden laptop:flex items-center justify-between px-4 py-2">
          <div
            class="w-6 h-6 text-content-default cursor-pointer"
            @click="closeSidebar"
          >
            <pc-icon name="close" />
          </div>
          <restaurant-image />
        </div>
        <div class="p-4 tablet:py-6 flex justify-between">
          <div>
            <p
              data-test="user-info-restaurant-name"
              class="font-bold mb-1 tablet:mb-0 text-content-link"
            >
              {{ info.name }}
            </p>
            <p class="pc-body-s text-content-subdued">
              {{ info.description }}
            </p>
          </div>
          <restaurant-image class="flex laptop:hidden flex-shrink-0 ml-2" />
        </div>
        <quick-links class="px-4" />
      </div>

      <div class="py-2 mt-auto">
        <ul>
          <pc-one-line-list
            v-if="accountSettingsRoute.isEnabled"
            compact
            divider
            left-content-type="icon"
            :href="accountSettingsRoute.path"
            @click.native="closeSidebar"
          >
            <template #leftContent>
              <icon-account-settings />
            </template>
            {{ $t('account_settings') }}
          </pc-one-line-list>
          <pc-one-line-list
            v-if="info.restaurantCount > 1"
            compact
            divider
            left-content-type="icon"
            href="/logout-restaurant"
          >
            <template #leftContent>
              <icon-change-restaurant />
            </template>
            {{ $t('change_restaurant') }}
          </pc-one-line-list>
          <pc-one-line-list
            compact
            left-content-type="icon"
            href="/logout"
          >
            <template #leftContent>
              <icon-logout />
            </template>
            {{ $t('log_out') }}
          </pc-one-line-list>
        </ul>
      </div>
    </template>
  </pc-sidebar>
</template>

<script>
import { PcIcon, PcSidebar, PcOneLineList } from '@je-pc/ui-components'
import RestaurantImage from './RestaurantImage'
import QuickLinks from './QuickLinks'
import IconAccountSettings from '../../icons/IconAccountSettings'
import IconChangeRestaurant from '../../icons/IconChangeRestaurant'
import IconLogout from '../../icons/IconLogout'

export default {
  components: {
    PcSidebar,
    QuickLinks,
    RestaurantImage,
    IconAccountSettings,
    IconChangeRestaurant,
    IconLogout,
    PcIcon,
    PcOneLineList
  },
  props: {
    info: {
      type: Object,
      required: true
    },
    routes: {
      type: Array,
      required: true
    }
  },
  computed: {
    accountSettingsRoute () {
      return this.routes.find(item => item.title === 'account_settings')
    }
  }
}
</script>
