<template>
  <pc-sidebar>
    <template #activator="{ openSidebar }">
      <span
        class="text-support-brand-01 w-8 h-8 flex items-center justify-center pointer"
        tabindex="-1"
        data-test="sidebar-trigger"
        @click="openSidebar"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        /></svg>
      </span>
    </template>
    <template #default="{ closeSidebar }">
      <div class="border-b border-color-default px-4 py-6 mb-6 ">
        <router-link
          :to="homeLink"
          class="flex mr-6 text-support-brand-01"
          @click.native="closeSidebar"
        >
          <logo :tenant="tenant" />
        </router-link>
      </div>
      <pc-left-side-navigation class="ml-1">
        <template v-for="link in items">
          <pc-left-side-navigation-item
            v-if="link.host"
            :key="link.title"
            rel="noopener"
            :href="link.host + link.path"
            @click.native="handleLinkClick(link.title)"
          >
            {{ $t(link.title) }}
          </pc-left-side-navigation-item>
          <pc-left-side-navigation-item
            v-else
            :key="link.title"
            :href="link.path"
            :exact="link.path === '/'"
            @click.native="handleLinkClick(link.title, closeSidebar)"
          >
            {{ $t(link.title) }}
          </pc-left-side-navigation-item>
        </template>
        <pc-left-side-navigation-item
          v-for="link in externalItems"
          :key="link.title"
          rel="noopener"
          class="pc-navigation-link px-4 py-3 flex align-center justify-between"
          target="_blank"
          :href="link.host + link.path"
          @click.native="handleLinkClick(link.title)"
        >
          {{ $t(link.title) }}
          <template #icon>
            <icon-external-link />
          </template>
        </pc-left-side-navigation-item>
      </pc-left-side-navigation>
    </template>
  </pc-sidebar>
</template>

<script>
import { PcSidebar, PcLeftSideNavigation, PcLeftSideNavigationItem } from '@je-pc/ui-components'
import IconExternalLink from '../icons/IconExternalLink.vue'
import Logo from './Logo'
import routerConfig from '@/router-config'
import { trackNavigationLink } from '@/services/gtm'

export default {
  components: {
    PcSidebar,
    IconExternalLink,
    PcLeftSideNavigation,
    Logo,
    PcLeftSideNavigationItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    tenant: {
      type: String,
      default: ''
    },
    externalItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    homeLink () {
      const { path } = routerConfig.items.find(item => item.title === 'home')
      return path
    }
  },
  methods: {
    handleLinkClick (title, closeSidebar) {
      if (closeSidebar) {
        closeSidebar()
      }
      trackNavigationLink(title)
    }
  }
}
</script>
