<template>
  <popup-banner
    v-model="show"
    data-test="emailLoginSetupReminderMessage"
  >
    <icon-email-login
      class="mb-4 laptop:mb-6 m-auto"
      :class="[breakpointUp('laptop') ? 'icon--big': 'icon--small']"
    />
    <p class="pc-heading-s text-center mb-5">
      {{ this.$t("email_login_title") }}
    </p>
    <p class="mb-4 text-center">
      {{ $t("email_login_message") }}
    </p>
    <pc-button
      size="small"
      class="w-full mb-4"
      @click="onSetupEmailLoginClicked"
    >
      {{ $t("email_login_setup") }}
    </pc-button>
    <pc-button
      type="ghost"
      size="small"
      class="w-full"
      data-test="remindLaterButton"
      @click="onRemindLaterClicked"
    >
      {{ $t("email_login_remind") }}
    </pc-button>
  </popup-banner>
</template>

<script>
import { mapState } from 'vuex'
import { navigateToUrl } from 'single-spa'
import { PcButton, breakpoint } from '@je-pc/ui-components'
import { validateEmailAddress, getIsFeatureEnabledInLocalStorage } from '@je-pc/utils'

import PopupBanner from './PopupBanner'
import MessageReminder from '../../helpers/messageReminder'
import IconEmailLogin from '../../icons/IconEmailLogin'
import { FEATURE_NAMES, EMAIL_LOGIN_REMIND_MESSAGE_NAME, EMAIL_LOGIN_REMIND_AFTER_MIN } from '../../constants'

export default {
  components: {
    PopupBanner,
    IconEmailLogin,
    PcButton
  },
  mixins: [breakpoint],
  props: {
    routes: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEmailLoginSetupRemindRequired: false,
      messageReminder: new MessageReminder(EMAIL_LOGIN_REMIND_MESSAGE_NAME, EMAIL_LOGIN_REMIND_AFTER_MIN)
    }
  },
  computed: {
    ...mapState(['user']),
    emailLoginSetupMessageRoute () {
      return this.routes.global_items.find(i => i.title === 'emailLoginSetupMessage')
    },
    show () {
      return this.isEnabled && this.showEmailLoginSetupMessage
    },
    isEnabled () {
      const isEnabledInLocalStorage = getIsFeatureEnabledInLocalStorage(FEATURE_NAMES.emailLoginSetup)
      if (isEnabledInLocalStorage !== null) return isEnabledInLocalStorage

      return this.emailLoginSetupMessageRoute?.isEnabled
    },
    showEmailLoginSetupMessage () {
      if (!this.isEmailLoginSetupRequired) {
        return false
      }

      if (this.$route.path.startsWith(this.emailLoginSetupMessageRoute.path)) {
        return false
      }

      if (!this.isEmailLoginSetupRemindRequired) {
        return false
      }

      return true
    },
    isEmailLoginSetupRequired () {
      return this.user && !this.user.isJustEatUser && !validateEmailAddress(this.user.name)
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (value) {
        this.isEmailLoginSetupRemindRequired = this.messageReminder.shouldRemind(value)
      }
    }
  },
  methods: {
    onRemindLaterClicked () {
      this.messageReminder.requestReminder(this.user)
      this.isEmailLoginSetupRemindRequired = this.messageReminder.shouldRemind(this.user)
    },
    onSetupEmailLoginClicked () {
      navigateToUrl(this.emailLoginSetupMessageRoute.path)
    }
  }
}
</script>

<style scoped>
  .icon--big {
    height: 164px;
    width: 164px;
  }

  .icon--small {
    height: 112px;
    width: 112px;
  }
</style>
