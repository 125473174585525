<template>
  <div class="quick-licks">
    <h4 class="font-bold py-3">
      Quick links
    </h4>
    <ul class="mb-2">
      <li
        v-for="link in availableLinks"
        :key="link.title"
        class="mb-4"
      >
        <router-link
          class="flex"
          :to="link.path"
          :exact="link.path === '/'"
        >
          <component :is="link.iconComponent" />
          <span class="pl-3">
            {{ $t(link.title) }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import routes from '@/router-config'
const icons = {
  IconItemAvailability: require('../../icons/quick-links/IconItemAvailability.vue').default,
  IconReviews: require('../../icons/quick-links/IconReviews.vue').default,
  IconOpeningHours: require('../../icons/quick-links/IconOpeningHours.vue').default,
  IconEditMenu: require('../../icons/quick-links/IconEditMenu.vue').default,
  IconInvoices: require('../../icons/quick-links/IconInvoices.vue').default,
  IconRefundRequests: require('../../icons/quick-links/IconRefundRequests.vue').default
}

export default {
  computed: {
    routes () {
      return routes
    },
    allLinks () {
      const menuRoutesConfig = this.getRoutesConfigByTitle('menu', true)
      const reviewsRoutesConfig = this.getRoutesConfigByTitle('reviews', false)
      const settingsRoutesConfig = this.getRoutesConfigByTitle('your_restaurant', true)
      const ordersRoutesConfig = this.getRoutesConfigByTitle('orders_and_invoices', true)

      return [
        {
          title: 'item_availability',
          path: menuRoutesConfig.menu.path,
          host: menuRoutesConfig.menu.host,
          isEnabled: menuRoutesConfig.menu.isEnabled,
          iconComponent: icons.IconInvoices
        },
        {
          title: 'reviews',
          path: reviewsRoutesConfig.path,
          host: reviewsRoutesConfig.host,
          isEnabled: reviewsRoutesConfig.isEnabled,
          iconComponent: icons.IconReviews
        },
        {
          title: 'opening_hours',
          path: settingsRoutesConfig.opening_hours.path,
          host: settingsRoutesConfig.opening_hours.host,
          isEnabled: settingsRoutesConfig.opening_hours.isEnabled,
          iconComponent: icons.IconOpeningHours
        },
        {
          title: 'invoice_history',
          path: ordersRoutesConfig.invoices.path,
          host: ordersRoutesConfig.invoices.host,
          isEnabled: ordersRoutesConfig.invoices.isEnabled,
          iconComponent: icons.IconInvoices
        },
        {
          title: 'customer_refunds',
          path: ordersRoutesConfig.customer_refunds.path,
          host: ordersRoutesConfig.customer_refunds.host,
          isEnabled: ordersRoutesConfig.customer_refunds.isEnabled && this.isCustomerRefundsEnabled,
          iconComponent: icons.IconRefundRequests
        }
      ]
    },
    availableLinks () {
      return this.allLinks.filter(item => item.isEnabled)
    },
    isCustomerRefundsEnabled () {
      return this.$store.state.settings.isCustomerRefundsEnabled
    }
  },
  methods: {
    getRoutesConfigByTitle (title, children = true) {
      const config = this.routes.items.find((x) => x.title === title)
      return children ? config.children : config
    }
  }
}
</script>

<style scoped>

</style>
