import { mapState } from 'vuex'
import { navigateToUrl } from 'single-spa'
import { validateEmailAddress, getIsFeatureEnabledInLocalStorage } from '@je-pc/utils'
import { FEATURE_NAMES, ACCOUNT_SETTINGS_PATH } from '../constants'
import routes from '../router-config'

export default {
  computed: {
    ...mapState(['user']),
    routes () {
      return routes
    },
    forceEmailLoginMigrationRoute () {
      return this.routes.global_items.find(
        (i) => i.title === 'forceEmailLoginMigration'
      )
    },
    isForceEmailLoginMigrationFeatureEnabled () {
      const isEnabledInLocalStorage = getIsFeatureEnabledInLocalStorage(
        FEATURE_NAMES.forceEmailLoginMigration
      )
      if (isEnabledInLocalStorage !== null) { return isEnabledInLocalStorage }

      return this.forceEmailLoginMigrationRoute?.isEnabled
    },
    isForceEmailLoginMigrationRequired () {
      return (
        this.user &&
        !this.user.isJustEatUser &&
        !validateEmailAddress(this.user.name)
      )
    }
  },
  methods: {
    handleForceEmailLoginMigration (href) {
      const checkForceEmailLoginMigration = this.checkForceEmailLoginMigration(href)
      if (checkForceEmailLoginMigration) navigateToUrl(this.forceEmailLoginMigrationRoute.path)
    },
    checkForceEmailLoginMigration (href = window.location.href) {
      if (
        !this.isForceEmailLoginMigrationFeatureEnabled ||
          !this.isForceEmailLoginMigrationRequired
      ) return false
      if (new URL(href).pathname.startsWith(ACCOUNT_SETTINGS_PATH)) return false
      return true
    }
  },
  created () {
    this.handleForceEmailLoginMigration()
    window.addEventListener('single-spa:before-routing-event', (evt) => {
      const { newUrl } = evt.detail
      this.handleForceEmailLoginMigration(newUrl)
    })
  }
}
