<template>
  <li
    class="block px-4 py-3 text-gray-700 cursor-pointer hover:bg-grey-20"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </li>
</template>

<script>
export default {}
</script>
