export default {
  uk: [
    {
      title: 'Increase orders with StampCards',
      textLines: [
        'Check out our new StampCards page.',
        'Giving you up to 21% more orders on average, StampCards helps you stand out and increase customer loyalty by up to 17%.',
        'Sign up for free in a few clicks.'
      ],
      cta: 'Join now',
      remind: 'Remind me later',
      link: '/marketing/stamp-cards',
      iconComponentName: 'IconStampCardsWithHands',
      startAt: '2023-10-01T00:00:00',
      endAt: '2023-10-31T23:59:59'
    }
  ],
  ie: [
    {
      title: 'Respond to reviews',
      textLines: ['We’ve made the reviews and ratings process fairer and easier for everyone.',
        'Let customers know that you’re listening and quickly resolve any issues.',
        'It’s simple to do on the Partner Hub.'],
      cta: 'See my reviews',
      remind: 'Remind me later',
      link: '/reviews?utm_source=partnerhub&utm_medium=popup&utm_campaign=partner_replies_2023',
      imageLink: '/navigation/assets/images/5_Star_Ratings_Illustration_Transparent.png',
      startAt: '2023-11-20T00:00:00',
      endAt: '2023-12-04T23:59:59'
    },
    {
      title: 'NEW: make 2-for-1 deals',
      textLines: ['Promotions help you convert more customers and put your business in the “offers’’ section on the app. Customise your 2-for-1 deals easily under the Marketing tab.'],
      cta: 'Create deals',
      remind: 'Remind me later',
      link: '/marketing/offers',
      imageLink: '/navigation/assets/images/Pop_up_Groceries_Buy_One_Get_One_Free_Launch.png',
      startAt: '2023-10-01T00:00:00',
      endAt: '2023-10-15T23:59:59'
    },
    {
      title: 'Increase orders with StampCards',
      textLines: [
        'Check out our new StampCards page.',
        'Giving you up to 21% more orders on average, StampCards helps you stand out and increase customer loyalty by up to 17%.',
        'Sign up for free in a few clicks.'
      ],
      cta: 'Join now',
      remind: 'Remind me later',
      link: '/marketing/stamp-cards',
      iconComponentName: 'IconStampCardsWithHands',
      startAt: '2023-10-16T00:00:00',
      endAt: '2023-10-31T23:59:59'
    }
  ],
  it: [
    {
      title: 'Rispondi alle recensioni',
      textLines: [
        'Il sistema di recensioni e valutazioni diventa più semplice ed equo.',
        'Rispondi ai tuoi clienti direttamente dal Partner Hub per far sapere loro che li ascolti e risolvere rapidamente ogni problema.'
      ],
      cta: 'Vedi recensioni',
      remind: 'Ricordamelo più tardi',
      link: '/reviews?utm_source=partnerhub&utm_medium=popup&utm_campaign=partner_replies_it_2023',
      imageLink: '/navigation/assets/images/5_Star_Ratings_Illustration_Transparent.png',
      startAt: '2023-11-27T00:00:00',
      endAt: '2023-12-11T23:59:59'
    },
    {
      title: 'Sono tornati i Just Eat Awards',
      textLines: [
        'È il tuo grande momento: raccontaci cos’ha di speciale il tuo ristorante.',
        'Non vediamo l’ora di festeggiare i tuoi successi.',
        'Le candidature chiudono il 6 ottobre.',
        '*Solo per ristoranti Partner indipendenti (no catene) che sono su Just Eat da almeno un anno.'
      ],
      cta: 'Candidati ora',
      remind: 'Ricordamelo più tardi',
      link: 'https://justeat.it/awards/2023/it/home?utm_source=partnerhub&utm_medium=popup&utm_campaign=bra2023',
      imageLink: '/navigation/assets/images/marketing_popup.png',
      startAt: '2023-09-13T00:00:00',
      endAt: '2023-10-06T23:59:59'
    },
    {
      title: 'NOVITÀ: crea offerte 2x1',
      textLines: ['Le promozioni generano più acquisti e permettono alla tua attività di comparire nella sezione “Offerte” dell’app. Personalizza le tue offerte 2x1 nella sezione Marketing: è facilissimo.'],
      cta: 'Crea offerte',
      remind: 'Ricordamelo più tardi',
      link: '/marketing/offers',
      imageLink: '/navigation/assets/images/Pop_up_Groceries_Buy_One_Get_One_Free_Launch.png',
      startAt: '2023-10-07T00:00:00',
      endAt: '2023-10-18T23:59:59'
    },
    {
      title: 'Incrementa gli ordini con le Carte Fedeltà',
      textLines: [
        'Dai un’occhiata alla nostra nuova pagina dedicata alle Carte Fedeltà.',
        'Le Carte Fedeltà ti aiutano a distinguerti incrementando la fidelizzazione dei clienti fino al 17% e portando, in media, il 21% in più di ordini.',
        'Aderisci gratuitamente in pochi clic.'
      ],
      cta: 'Iscriviti ora',
      remind: 'Ricordamelo più tardi',
      link: '/marketing/stamp-cards',
      iconComponentName: 'IconStampCardsWithHands',
      startAt: '2023-10-19T00:00:00',
      endAt: '2023-10-31T23:59:59'
    }
  ],
  es: [
    {
      title: 'Responde a las reseñas',
      textLines: [
        'Hemos simplificado el sistema de valoración y puntuación pensando en ti y en tus clientes.',
        'Ahora desde el Partner Hub podrás contestar a todas las reseñas que hayan compartido.'
      ],
      cta: 'Ver reseñas',
      remind: 'Recuérdamelo más tarde',
      link: '/reviews?utm_source=partnerhub&utm_medium=popup&utm_campaign=partner_replies_es_2023',
      imageLink: '/navigation/assets/images/5_Star_Ratings_Illustration_Transparent.png',
      startAt: '2023-12-04T00:00:00',
      endAt: '2023-12-18T23:59:59'
    },
    {
      title: 'Vuelven los Premios Just Eat',
      textLines: [
        'Llego el gran momento: cuéntanos qué hace especial a tu negocio para celebrarlo juntos.',
        'Inscríbete hasta el 29 de septiembre.',
        '*Aplica solo para restaurantes independientes asociados (no participan las cadenas)'
      ],
      cta: 'Inscríbete',
      remind: 'Recuérdamelo más tarde',
      link: 'https://just-eat.es/awards/2023/es/home?utm_source=partnerhub&utm_medium=popup&utm_campaign=bra2023',
      imageLink: '/navigation/assets/images/marketing_popup.png',
      startAt: '2023-09-13T00:00:00',
      endAt: '2023-09-29T23:59:59'
    },
    {
      title: 'Nuevo: Crea ofertas 2x1',
      textLines: ['Las promociones te ayudan a captar más clientes y a dar visibilidad a tu negocio en la sección de “Ofertas” de la app. Personalízalas fácilmente en la pestaña “Marketing”.'],
      cta: 'Crear ofertas',
      remind: 'Recuérdamelo más tarde',
      link: '/marketing/offers',
      imageLink: '/navigation/assets/images/Pop_up_Groceries_Buy_One_Get_One_Free_Launch.png',
      startAt: '2023-10-01T00:00:00',
      endAt: '2023-10-15T23:59:59'
    },
    {
      title: 'Aumenta tus pedidos con Tarjetas de sellos',
      textLines: [
        'Echa un vistazo a nuestra nueva Tarjetas de sellos.',
        'Incrementa hasta un 21% tus pedidos, destácate y aumenta hasta un 17% la fidelidad de tus clientes.',
        'Darte de alta es totalmente gratis y tan solo te tomará un par de clics.'
      ],
      cta: 'Únete',
      remind: 'Recuérdamelo más tarde',
      link: '/marketing/stamp-cards',
      iconComponentName: 'IconStampCardsWithHands',
      startAt: '2023-10-16T00:00:00',
      endAt: '2023-10-31T23:59:59'
    }
  ],
  au: [
    {
      title: 'Respond to reviews',
      textLines: ['We’ve made the reviews and ratings process fairer and easier for everyone.',
        'Let customers know that you’re listening and quickly resolve any issues.',
        'It’s simple to do on the Partner Hub.'],
      cta: 'See my reviews',
      remind: 'Remind me later',
      link: '/reviews?utm_source=partnerhub&utm_medium=popup&utm_campaign=partner_replies_anz_2023',
      imageLink: '/navigation/assets/images/5_Star_Ratings_Illustration_Transparent.png',
      startAt: '2023-12-11T00:00:00',
      endAt: '2023-12-25T23:59:59'
    }
  ],
  nz: [
    {
      title: 'Respond to reviews',
      textLines: ['We’ve made the reviews and ratings process fairer and easier for everyone.',
        'Let customers know that you’re listening and quickly resolve any issues.',
        'It’s simple to do on the Partner Hub.'],
      cta: 'See my reviews',
      remind: 'Remind me later',
      link: '/reviews?utm_source=partnerhub&utm_medium=popup&utm_campaign=partner_replies_anz_2023',
      imageLink: '/navigation/assets/images/5_Star_Ratings_Illustration_Transparent.png',
      startAt: '2023-12-11T00:00:00',
      endAt: '2023-12-25T23:59:59'
    }
  ]
}
