import { ApiClient } from '@je-pc/api'
import { SMARTGATEWAY_URL } from '../constants'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)

export const getSettings = (params) => {
  return http.get(
    `/applications/partnercentre/{tenant}/{restaurantId}/settings?settings=${params}`
  )
    .then(response => {
      return response.data
    })
    .catch(_ => {
      return {}
    })
}
