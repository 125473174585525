<template>
  <div
    class="shadow-elevation-03 tablet:shadow-elevation-00 px-4 laptop:px-6 py-3 tablet:pb-2 tablet:pt-0 mb-6 tablet:mb-0 tablet:mt-10"
  >
    <div class="container">
      <div class="flex align-center">
        <div class="flex justify-start tablet:w-2/12 heading-actions">
          <portal-target name="pcHeading_leftSlot">
            <heading-link variant="back" />
          </portal-target>
        </div>
        <div class="heading-title flex flex-grow items-center justify-center overflow-hidden px-1">
          <portal-target name="pcHeading_default">
            <h1
              v-if="isMobile"
              class="text-center pc-subheading-l truncate"
            >
              {{ pageTitle }}
            </h1>
          </portal-target>
        </div>
        <div class="flex justify-end tablet:w-2/12 heading-actions">
          <portal-target name="pcHeading_rightSlot" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue'
import { breakpoint } from '@je-pc/ui-components'
import HeadingLink from './helpers/HeadingLink'

export default {
  components: {
    PortalTarget,
    HeadingLink
  },
  mixins: [breakpoint],
  props: {
    preparedRoute: {
      type: String,
      required: true
    }
  },
  computed: {
    getBackUrl () {
      const { referer } = this.$route.query
      if (referer) {
        return referer
      }
      const routesList = this.preparedRoute.split('/')
      routesList.pop()
      return routesList.join('/')
    },
    linkEl () {
      return this.getBackUrl.match(/^(http(s)?):\/\//) ? 'a' : 'router-link'
    }
  }
}
</script>

<style scoped>
.heading-actions {
  min-width: 2rem;
  min-height: 2rem;
}
.heading-title > * {
  min-width: 0;
}
</style>
