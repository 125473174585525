<template>
  <div class="w-8 tablet:w-10 h-8 tablet:h-10 border-2 border-color-default rounded-radius-b overflow-hidden">
    <img
      v-if="restaurantInfo && !logoError"
      class="rounded-radius-b w-full h-full"
      :src="restaurantInfo.logoUrl"
      alt=""
      width="46"
      height="46"
      @error="logoError = true"
    >
    <svg
      v-else
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="rounded w-full h-full"
    >
      <rect
        stroke="none"
        width="70"
        height="70"
        style="fill:#ccc"
      />
    </svg>
  </div>
</template>

<script>
export default {
  data: () => ({
    logoError: false
  }),
  computed: {
    restaurantInfo () {
      return this.$store.state.user?.restaurant
    }
  }
}
</script>
