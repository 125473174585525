import { trackCustomEvent } from '@je-pc/tracking'

export const trackLoansBannerImpression = () => {
  trackCustomEvent('banner_loan_impression')
}

export const trackLoansBannerClick = () => {
  trackCustomEvent('banner_loan_clicks')
}

export const trackNavigationLink = (title) => {
  trackCustomEvent('Navigation', {
    groupCategory: 'Navigation',
    label: title
  })
}
