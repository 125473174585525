<template>
  <pc-error-boundary @on-error="handleComponentError">
    <div>
      <navigation
        :tenant="tenant"
        :routes="routes"
        :hide-heading="hideHeading"
        :disable-navigation="isNavigationDisabled"
      />
      <pc-page-transition v-if="isNavigationView">
        <router-view :routes="routes" />
      </pc-page-transition>
      <popup-banner-message :routes="routes" />
      <pc-toast />
    </div>
  </pc-error-boundary>
</template>

<script>
import { eventBus } from '@je-pc/utils'
import { PcPageTransition, PcErrorBoundary } from '@je-pc/ui-components'
import { HIDE_HEADING_ROUTES, TENANT } from './constants'
import routes from '@/router-config'
import logger from '@/services/logger'
import PopupBannerMessage from './components/global/PopupBannerMessage'
import Navigation from './components/Navigation'
import useForceEmailLoginMigration from '@/mixins/useForceEmailLoginMigration'

export default {
  components: {
    PopupBannerMessage,
    Navigation,
    PcPageTransition,
    PcErrorBoundary
  },
  mixins: [useForceEmailLoginMigration],
  props: {
    singleSpa: {
      type: Object,
      default: null
    },
    rootApps: {
      type: Array,
      default: () => []
    },
    getNavigationDisabled: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      tenant: TENANT,
      isNavigationView: false,
      isNavigationDisabled: false,
      showedAuthError: false
    }
  },
  computed: {
    routes () {
      return routes
    },
    isHideHeadingByRoute () {
      return HIDE_HEADING_ROUTES.some(path => this.$route.path.startsWith(path))
    },
    hideHeading () {
      if (this.isHideHeadingByRoute) return true
      return this.isNavigationView && this.$route.name === '404'
    }
  },
  mounted () {
    this.checkNavigationState()

    eventBus.on('invalid-data', async ({ type }) => {
      if (type === 'restaurant-id') {
        location.reload()
      }
    })

    eventBus.on('auth-error', ({ type, level }) => {
      if (this.showedAuthError) return
      this.showedAuthError = true
      this.$notify({
        type: 'error',
        autoclose: false,
        closeButton: false,
        message: this.$t('error_unavailable')
      })
    })

    window.addEventListener('single-spa:before-routing-event', (evt) => {
      const { newUrl, oldUrl } = evt.detail
      this.checkNavigationState(newUrl, oldUrl)
    })
  },
  methods: {
    handleComponentError ({ error, loggerPayload }) {
      logger.error(error, loggerPayload)
    },
    checkNavigationState (href = window.location.href, referrer = '') {
      this.checkIsNavigationView(href, referrer)
      this.checkIsNavigationDisabled()
    },
    checkIsNavigationView (href, referrer) {
      this.isNavigationView = false
      const url = new URL(href)
      // If Navigation hub page show it
      const { resolved } = this.$router.resolve(url.pathname)
      if (resolved?.name !== '404') {
        this.isNavigationView = true
        return
      }

      // Find all root active applications except layout apps
      const activeNotRootAppsByUrl = this.singleSpa
        .checkActivityFunctions(url)
        .filter((app) => !this.rootApps.includes(app))

      // If there are no root active apps, this should be a 404 navigation page
      this.isNavigationView = activeNotRootAppsByUrl.length === 0

      if (this.isNavigationView && resolved?.name === '404') {
        logger.info('Page not found', { href, referrer })
      }
    },
    checkIsNavigationDisabled () {
      if (this.getNavigationDisabled) {
        this.isNavigationDisabled = this.getNavigationDisabled()
      }
    }
  }
}
</script>
