<template>
  <div>
    <portal
      v-for="portal in filledPortals"
      :key="portal"
      :to="`pcHeading_${portal}`"
    >
      <slot :name="portal" />
    </portal>
  </div>
</template>

<script>
import { Portal } from 'portal-vue'
export default {
  components: {
    Portal
  },
  data () {
    return {
      portals: ['leftSlot', 'default', 'rightSlot']
    }
  },
  computed: {
    filledPortals () {
      return this.portals.filter((portal) => this.$slots[portal])
    }
  }
}
</script>
