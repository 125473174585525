<template>
  <div>
    <marketing-message
      v-if="showMarketingPopupMessage"
      :marketing-message="marketingMessage"
    />
    <email-login-setup-reminder-message
      v-else
      :routes="routes"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getIsFeatureEnabledInLocalStorage } from '@je-pc/utils'

import MarketingMessage from './MarketingMessage.vue'
import EmailLoginSetupReminderMessage from './EmailLoginSetupReminderMessage.vue'
import { FEATURE_NAMES, IS_MARKETING_POPUP_BANNER_ENABLED } from '../../constants'

export default {
  components: {
    MarketingMessage,
    EmailLoginSetupReminderMessage
  },
  props: {
    routes: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({ marketingMessage: 'getMarketingMessage' }),
    showMarketingPopupMessage () {
      return this.isMarketingPopupBannerEnabled && this.marketingMessage
    },
    isMarketingPopupBannerEnabled () {
      const isEnabledInLocalStorage = getIsFeatureEnabledInLocalStorage(FEATURE_NAMES.marketingMessage)
      if (isEnabledInLocalStorage !== null) return isEnabledInLocalStorage

      return IS_MARKETING_POPUP_BANNER_ENABLED
    }
  }
}
</script>
