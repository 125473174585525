<template>
  <div
    class="relative"
  >
    <div
      :class="{ 'is-active bg-grey-30 hover:opacity-100': isOpened }"
      tabindex="0"
      class="
        flex
        items-center
        space-x-2
        cursor-pointer
        hover:opacity-90
        w-8
        h-8
        rounded-full
        flex
        justify-center
      "
      data-test="user-info-trigger"
      @click.stop="toggle()"
    >
      <pc-icon
        name="moreVertical"
        class="w-6 h-6"
      />
    </div>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-200 ease-out"
      leave-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <ul
        v-show="isOpened"
        ref="dropdown"
        class="
          absolute
          bg-white
          rounded-sm
          box-border
          shadow-lg
          py-2
          w-48
          right-0
          mt-3
          z-20
          flex flex-col
        "
      >
        <slot />
      </ul>
    </transition>
  </div>
</template>

<script>

import { PcIcon, isMobile } from '@je-pc/ui-components'

export default {
  components: {
    PcIcon
  },
  mixins: [isMobile],
  data () {
    return {
      isOpened: false
    }
  },
  watch: {
    isOpened (value) {
      this.shouldListenOutsideClick(value)
    }
  },
  beforeDestroy () {
    this.shouldListenOutsideClick(false)
  },
  methods: {
    toggle () {
      this.isOpened = !this.isOpened
    },
    handleClickOutside (event) {
      const dropdownEl = this.$refs.dropdown
      if (dropdownEl === event.target) return
      this.isOpened = false
    },
    shouldListenOutsideClick (listen) {
      return listen
        ? document.addEventListener('click', this.handleClickOutside)
        : document.removeEventListener('click', this.handleClickOutside)
    }
  }
}
</script>
