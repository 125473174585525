import Vue from 'vue'
import Vuex from 'vuex'
import { getSettings } from '../services/http'
import marketingMessages from '../helpers/marketingMessages'
import { TENANT } from '../constants'
import {
  LOAD_SETTINGS
} from './actions.types'
import {
  SET_SETTINGS,
  SET_LOADING,
  SET_USER
} from './mutations.types'
import { createStore } from '@je-pc/utils'

Vue.use(Vuex)
export default createStore(Vuex.Store, {
  state: {
    user: null,
    isLoading: false,
    settings: {},
    marketingMessages
  },
  mutations: {
    [SET_SETTINGS] (state, settings) {
      const keys = Object.keys(settings)
      if (keys.length) {
        keys.forEach(key => {
          if (settings[key] === 'true' || settings[key] === 'false') {
            Vue.set(state.settings, key, settings[key] === 'true')
          } else {
            Vue.set(state.settings, key, settings[key])
          }
        })
      }
    },
    [SET_LOADING] (state, value) {
      state.isLoading = value
    },
    [SET_USER] (state, value) {
      state.user = value
    }
  },
  actions: {
    async [LOAD_SETTINGS] ({ state, commit }, settings) {
      if (!settings.length || settings.some((item) => Object.keys(state.settings).includes(item))) {
        return
      }
      commit(SET_LOADING, true)
      const params = settings.join(',')
      const response = await getSettings(params)
      if (response.settings) {
        commit(SET_SETTINGS, response.settings)
      }
      commit(SET_LOADING, false)
    }
  },
  getters: {
    getMarketingMessage (state) {
      const now = Date.now()

      const getTimeBasedMarketingMessage = () => state.marketingMessages[TENANT].find(m => {
        const startAt = Date.parse(m.startAt)
        const endAt = Date.parse(m.endAt)

        if (startAt && endAt) return now >= startAt && now <= endAt
        if (startAt) return now >= startAt
        if (endAt) return now <= endAt
        return false
      })

      const getDefaultMarketingMessage = () => state.marketingMessages[TENANT].find(m => !m.startAt && !m.endAt)

      return getTimeBasedMarketingMessage() ?? getDefaultMarketingMessage()
    }
  }
})
