<template>
  <header class="relative flex flex-col w-full antialiased">
    <div
      v-show="visibleHeader"
      class="flex items-center justify-between bg-container-default shadow-elevation-04 laptop:shadow-elevation-03 px-4 laptop:px-6 min-h-14"
    >
      <mobile-navigation
        v-if="!isDisabledNavigation"
        class="flex desktop:hidden"
        data-test="mobile-navigation"
        :tenant="tenant"
        :items="enabledItems"
        :external-items="enabledExternalItems"
        :info="user && user.restaurant"
      />

      <router-link
        :to="homeLink"
        class="flex text-support-brand-01 desktop:mr-6"
      >
        <icon-header-logo />
      </router-link>

      <div
        v-if="!isDisabledNavigation"
        class="hidden desktop:block flex-grow"
      >
        <div class="pc-navigation-horizontal flex items-stretch justify-start laptop:space-x-0 box-border min-h-14">
          <template v-for="link in enabledItems">
            <pc-tab
              v-if="link.host"
              :key="link.title"
              :href="link.host + link.path"
              rel="noopener"
              class="desktop:px-3"
              @click.native="handleLinkClick(link.title)"
            >
              {{ $t(link.title) }}
            </pc-tab>
            <pc-tab
              v-else
              :key="link.title"
              :exact="link.path === '/'"
              :href="link.path"
              class="desktop:px-3"
              @click.native="handleLinkClick(link.title)"
            >
              {{ $t(link.title) }}
            </pc-tab>
          </template>
        </div>
      </div>

      <div
        v-if="!isDisabledNavigation"
        data-test="desktop-navigation"
        class="flex items-center"
      >
        <nav
          class="hidden desktop:flex items-center space-x-6 pc-body-s font-bold"
        >
          <span v-if="user.isJustEatUser">
            Logged in as: {{ user.name }}
          </span>
          <a
            v-for="link in enabledExternalItems"
            :key="link.title"
            rel="noopener"
            class="flex items-center hover:underline text-content-link"
            target="_blank"
            :href="link.host + link.path"
          >
            <span>{{ $t(link.title) }}</span>
            <icon-external-link class="ml-2 w-4 h-4" />
          </a>
        </nav>

        <user-info
          :info="user.restaurant"
          :routes="routes.user_info"
          class="desktop:ml-6"
        />
      </div>
    </div>

    <heading
      v-if="enabledHeading"
      :prepared-route="preparedRoute"
    />
  </header>
</template>

<script>
import { trackNavigationLink } from '../services/gtm'
import { isAuthorized } from '@je-pc/auth'
import { breakpoint, PcTab } from '@je-pc/ui-components'
import routerConfig from '@/router-config'
import UserInfo from './user-info/UserInfo'
import Heading from './Heading'
import IconExternalLink from '../icons/IconExternalLink'
import IconHeaderLogo from '../icons/IconHeaderLogo'
import MobileNavigation from './MobileNavigation'

export default {
  name: 'PcNavigation',
  components: {
    PcTab,
    UserInfo,
    MobileNavigation,
    Heading,
    IconHeaderLogo,
    IconExternalLink
  },
  mixins: [breakpoint],
  props: {
    tenant: {
      type: String,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    hideHeading: {
      type: Boolean,
      default: false
    },
    disableNavigation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    homeLink () {
      const { path } = routerConfig.items.find(item => item.title === 'home')
      return path
    },
    user () {
      return this.$store.state.user
    },
    isAuthorized,
    isDisabledNavigation () {
      if (this.disableNavigation) return true
      if (this.user === null) return true

      return this.user.hideSubnavigation && this.isAuthorized === false
    },
    enabledItems () {
      return this.routes.items.filter(item => item.isEnabled)
    },
    enabledExternalItems () {
      return this.routes.external_items.filter(item => item.isEnabled)
    },
    preparedRoute () {
      const { path } = this.$route
      return path.endsWith('/') ? path.slice(0, -1) : path
    },
    enabledHeading () {
      if (this.hideHeading) return false
      return this.preparedRoute.split('/').length > 2
    },
    visibleHeader () {
      return !(this.isMobile && this.enabledHeading)
    }
  },
  methods: {
    handleLinkClick (title) {
      trackNavigationLink(title)
    }
  }
}
</script>

<style scoped>
.pc-navigation-horizontal>:not(template)~:not(template) {
  padding-left: .4rem;
  padding-right: .4rem;
}
@media (min-width: 1280px) {
  .pc-navigation-horizontal>:not(template)~:not(template) {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}
</style>
