export default {
  items: [
    {
      title: 'home',
      path: '/home',

      isEnabled: true
    },
    {
      title: 'your_restaurant',
      path: '/settings',

      children: {
        order_time: {
          path: '/settings/order-time',

          settingsKeys: ['RestaurantOrderTimeVersion'],
          isEnabled: true
        },
        delivery_area: {
          path: '/settings/delivery-management/area',

          isEnabled: true
        },
        delivery_charges: {
          path: '/settings/delivery-charges',

          settingsKeys: ['DeliveryFeesModel'],
          isEnabled: true
        },
        delivery_charges_new: {
          path: '/settings/delivery-management/charges',

          settingsKeys: ['DeliveryFeesModel', 'restaurantHasRdsSubscriptions'],
          isEnabled: true
        },
        opening_hours: {
          path: '/settings/opening-hours',

          isEnabled: true
        },
        view_details: {
          path: '/settings/restaurant-details',

          isEnabled: true
        },
        loan_banner: {
          path: 'https://youlend.com/apply/justeat/welcome',

          isEnabled: true
        },
        email_login: {
          path: '/settings/account',

          isEnabled: false
        },
        knowledge_centre: {
          path: '/knowledge-centre',
          host: '//partner.just-eat.co.uk',

          isEnabled: true
        }
      },
      isEnabled: true
    },
    {
      title: 'menu',
      path: '/menu',

      children: {
        manage_menu: {
          path: '/menu/edit',

          settingsKeys: ['isRestaurantMenuManagementEnabled'],
          isEnabled: true
        },
        menu: {
          path: '/menu/item-availability',

          isEnabled: true
        }
      },
      isEnabled: true
    },
    {
      title: 'orders_and_invoices',
      path: '/orders-and-invoices',

      children: {
        invoices: {
          path: '/orders-and-invoices/invoices',

          isEnabled: true
        },
        order_history: {
          path: '/orders-and-invoices/order-history',

          isEnabled: true
        },
        customer_refunds: {
          path: '/orders-and-invoices/customer-refunds',

          settingsKeys: ['isCustomerRefundsEnabled'],
          isEnabled: true
        }
      },
      isEnabled: true
    },
    {
      title: 'reviews',
      path: '/reviews',

      isEnabled: true
    },
    {
      title: 'business_help',
      path: '/help',

      children: {
        accountSecurity: {
          path: '/help/account-security',

          isEnabled: true
        },
        businessEssentials: {
          path: '/help/business-essentials',

          isEnabled: true
        },
        commonQuestions: {
          path: '/help/common-questions',

          isEnabled: true
        },
        supportRequests: {
          path: '/help/support-requests',

          isEnabled: true
        },
        sendMessage: {
          path: '/help/send-message',

          isEnabled: true
        },
        termsConditions: {
          path: '/help/terms-and-conditions',

          isEnabled: true
        },
        privacyPolicy: {
          path: '/help/privacy-policy',

          isEnabled: true
        },
        cookiesPolicy: {
          path: '/help/cookies-policy',

          isEnabled: true
        }
      },
      isEnabled: true
    },
    {
      title: 'marketing',
      path: '/marketing',

      children: {
        promotedPlacement: {
          path: '/marketing/promoted-placement',

          isEnabled: true
        },
        offers: {
          path: '/marketing/offers',

          isEnabled: true
        },
        stampCards: {
          path: '/marketing/stamp-cards',

          isEnabled: true
        }
      },
      isEnabled: true
    },
    {
      title: 'analytics',
      path: '/analytics/orders-and-sales',

      isEnabled: true
    }],
  user_info: [
    {
      title: 'account_settings',
      path: '/account-settings',
      isEnabled: true
    }],
  external_items: [
    {
      title: 'partner_blog',
      path: '/',
      host: '//partner.just-eat.co.uk/blog',
      isEnabled: false
    },
    {
      title: 'shop',
      path: '/',
      host: '//shop.just-eat.com',
      isEnabled: false
    },
    {
      title: 'extra_helpings',
      path: '/',
      host: '//extrahelpings.just-eat.co.uk',
      isEnabled: true
    },
    {
      title: 'knowledge_centre_link',
      path: '/knowledge-centre',
      host: '//partner.just-eat.co.uk',
      isEnabled: true
    }],
  global_items: [
    {
      title: 'emailLoginSetupMessage',
      path: '/settings/account',
      isEnabled: false
    },
    {
      title: 'forceEmailLoginMigration',
      path: '/settings/account/username-expired',
      isEnabled: false
    }]
}
