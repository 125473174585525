export default {
  home: 'Home',
  partner_centre: 'Partner Centre',
  account_settings: 'Account settings',
  change_restaurant: 'Change Restaurant',
  log_out: 'Log Out',
  your_restaurant: 'Business settings',
  orders_and_invoices: 'Orders & invoices',
  reviews: 'Reviews',
  business_help: 'Business help',
  promoted_placement: 'Promoted Placement',
  partner_blog: 'Partner blog',
  shop: 'Just Eat shop',
  extra_helpings: 'Extra Helpings',
  knowledge_centre_link: 'Knowledge Centre',
  invoice_history: 'Invoices',
  invoice_text: 'View and download your Just Eat invoices',
  order_history: 'Order history',
  order_text: 'Review your Just Eat orders and sales revenue',
  customer_refunds: 'Customer refunds',
  customer_refunds_text: 'Manage customer refund requests',
  account_security: 'Account security',
  account_security_text: 'Protect yourself from scams and fraudsters',
  business_essentials: 'Business essentials',
  business_essentials_text: 'Tips, advice and resources',
  common_questions: 'Common questions',
  common_questions_text: 'Answers to popular queries',
  support_requests: 'Your support requests',
  support_requests_text: 'Track your requests',
  send_message: 'Send us a message',
  send_message_text: 'Get in touch with the team',
  other_support_links: 'Other support links',
  terms_conditions: 'Terms and conditions',
  privacy_policy: 'Privacy policy',
  cookies_policy: 'Cookies policy',
  back: 'Back',
  marketing: 'Marketing',
  promoted_placement_text: 'Appear in the most visible slots in customers’ search results',
  offers: 'Offers',
  offers_text: 'Set up special offers and discounts for your customers',
  stamp_cards: 'StampCards',
  stamp_cards_text: 'Boost repeat orders and reward loyal customers',
  new: 'New',
  order_time: 'Order time',
  order_time_text: 'Set the time you need to prepare orders',
  menu: 'Menu/Items',
  manage_menu: 'Manage your menu',
  manage_menu_text: 'View and edit your menu items',
  item_availability: 'Item availability',
  item_availability_text: 'Take items off menu temporarily if they go out of stock',
  delivery_area: 'Delivery area',
  delivery_area_text: 'Manage the area you deliver to',
  delivery_charges: 'Delivery fees',
  delivery_charges_text: 'Manage how much you charge for delivery',
  opening_hours: 'Opening hours',
  opening_hours_text: 'Set the days and times you want to take orders',
  view_details: 'Restaurant details',
  view_details_text:
    'Make changes to your restaurant and bank details, and bag fees',
  invalid_data_title:
    'You’re currently managing the details for a different branch to this one',
  invalid_restaurant_title: 'You’re currently managing {restaurantName}',
  invalid_data_message: 'Refresh the page to make changes here instead.',
  reload_page: 'Refresh',
  loan_banner_title: 'Fast and flexible business<br />funding from YouLend',
  loan_banner_body:
    "Get funding from £500-£1million with flexible business funding from the UK's leading alternative finance provider.",
  loan_banner_action: 'Get a quote',
  email_login: 'Set up email log in',
  email_login_text: 'Log in to Partner Centre using your email',
  email_login_title: 'The way you log in to Partner Centre is changing',
  email_login_message:
    'Soon you’ll need to log in using your email address instead of a username. You can make the change now. It only takes a couple of minutes.',
  email_login_setup: 'Set up email log in',
  email_login_remind: 'Remind me later',
  knowledge_centre_title: 'Want to get more out of your tools?',
  knowledge_centre_text: 'Get tips and guidance on Knowledge Centre',
  analytics: 'Analytics',
  error_unavailable: 'Website currently unavailable'
}
