<template>
  <popup-banner
    v-model="show"
    data-test="marketingMessage"
  >
    <component
      :is="imageComponentName"
      v-bind="imageProps"
      class="mb-4 mt-6 laptop:mb-6 m-auto"
      :class="[breakpointUp('laptop') ? 'icon--big': 'icon--small']"
    />
    <p class="pc-heading-s text-center mb-5">
      {{ marketingMessage.title }}
    </p>
    <p
      v-for="(line, index) in marketingMessage.textLines"
      :key="index"
      class="text-center"
      :class="index === marketingMessage.textLines.length - 1 ? 'mb-4' : 'mb-1'"
    >
      {{ line }}
    </p>
    <pc-button
      size="small"
      class="w-full mb-4"
      :href="marketingMessage.link"
    >
      {{ marketingMessage.cta }}
    </pc-button>
    <pc-button
      type="ghost"
      size="small"
      class="w-full"
      data-test="remindLaterButton"
      @click="onRemindLaterClicked"
    >
      {{ marketingMessage.remind }}
    </pc-button>
  </popup-banner>
</template>

<script>
import { mapState } from 'vuex'
import { PcButton, breakpoint } from '@je-pc/ui-components'

import PopupBanner from './PopupBanner'
import MessageReminder from '../../helpers/messageReminder'
import IconStampCardsWithHands from '../../icons/IconStampCardsWithHands'
import IconChefHoldingBags from '../../icons/IconChefHoldingBags'
import { MARKETING_MESSAGE_NAME, MARKETING_MESSAGE_REMIND_AFTER_MIN } from '../../constants'

export default {
  components: {
    PopupBanner,
    IconStampCardsWithHands,
    IconChefHoldingBags,
    PcButton
  },
  mixins: [breakpoint],
  props: {
    marketingMessage: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isRemindRequired: false,
      messageReminder: new MessageReminder(MARKETING_MESSAGE_NAME, MARKETING_MESSAGE_REMIND_AFTER_MIN)
    }
  },
  computed: {
    ...mapState(['user']),
    show () {
      if (this.$route.path.startsWith(this.marketingMessage.link.split('?')[0])) {
        return false
      }

      if (!this.isRemindRequired) {
        return false
      }

      return true
    },
    imageComponentName () {
      return this.marketingMessage.imageLink ? 'img' : this.marketingMessage.iconComponentName
    },
    imageProps () {
      return this.marketingMessage.imageLink ? { src: this.marketingMessage.imageLink } : {}
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (value) {
        this.isRemindRequired = this.messageReminder.shouldRemind(value)
      }
    }
  },
  methods: {
    onRemindLaterClicked () {
      this.messageReminder.requestReminder(this.user)
      this.isRemindRequired = this.messageReminder.shouldRemind(this.user)
    }
  }
}
</script>

<style scoped>
  .icon--big {
    height: 164px;
  }

  .icon--small {
    height: 112px;
  }
</style>
