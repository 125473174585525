<template>
  <div
    v-if="value"
    class="
        fixed z-50
        inset-0 laptop:right-0 laptop:bottom-0 laptop:top-auto laptop:left-auto
        flex items-end justify-center laptop:justify-end
        laptop:w-1/3
      "
    tabindex="-1"
  >
    <div class="absolute laptop:hidden inset-0 bg-grey-100 opacity-50" />
    <div
      ref="banner"
      class="
          absolute overflow-y-auto bg-container-default
          rounded-radius-c laptop:shadow-elevation-01
          px-4 pb-4 mb-6 laptop:mb-8 laptop:mr-6
          w-11/12 laptop:w-full
        "
    >
      <slot>
        <p>Default content</p>
      </slot>
    </div>
  </div>
</template>

<script>
import { breakpoint } from '@je-pc/ui-components'
const OVERFLOW_HIDDEN_CLASS = 'overflow-hidden'

export default {
  mixins: [breakpoint],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.manageScroll(value)
      }
    },
    currentBreakpoint () {
      this.manageScroll(this.value)
    }
  },
  beforeDestroy () {
    this.manageScroll(false)
  },
  methods: {
    modalBehaviorRequired (value) {
      return value && !this.breakpointUp('laptop')
    },
    manageScroll (value) {
      if (this.modalBehaviorRequired(value)) {
        this.$nextTick(() => { this.disableScroll() })
        return
      }

      this.enableScroll()
    },
    enableScroll () {
      document.body.classList.remove(OVERFLOW_HIDDEN_CLASS)
    },
    disableScroll () {
      document.body.classList.add(OVERFLOW_HIDDEN_CLASS)
    }
  }
}
</script>
