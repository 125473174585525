import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { pageTitle, PcToast } from '@je-pc/ui-components'
import { subscribeToUserUpdates } from '@je-pc/auth'

import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import { SET_USER } from './store/mutations.types'

Vue.mixin(pageTitle)
Vue.config.productionTip = false

Vue.use(PcToast, {
  closeButton: null
})

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render (h) {
      return h(App, {
        props: {
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          rootApps: this.layoutRootApps,
          getNavigationDisabled: this.getNavigationDisabled
        }
      })
    },
    router,
    i18n,
    store
  }
})

let userSubscription = null

export const bootstrap = vueLifecycles.bootstrap

export const mount = (prop) => {
  userSubscription = subscribeToUserUpdates((user) => {
    store.reset()
    store.commit(SET_USER, user)
  })
  return vueLifecycles.mount(prop)
}

export const unmount = (prop) => {
  userSubscription?.unsubscribe()
  return vueLifecycles.unmount(prop)
}

export { default as PcHeadingProvider } from './components/helpers/HeadingProvider'
export { default as PcHeadingLink } from './components/helpers/HeadingLink'
export { default as PcHeadingActionsList } from './components/helpers/HeadingActionsList'
export { default as PcHeadingActionsListItem } from './components/helpers/HeadingActionsListItem'
