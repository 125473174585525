import { render, staticRenderFns } from "./EmailLoginSetupReminderMessage.vue?vue&type=template&id=259ae159&scoped=true&"
import script from "./EmailLoginSetupReminderMessage.vue?vue&type=script&lang=js&"
export * from "./EmailLoginSetupReminderMessage.vue?vue&type=script&lang=js&"
import style0 from "./EmailLoginSetupReminderMessage.vue?vue&type=style&index=0&id=259ae159&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259ae159",
  null
  
)

export default component.exports